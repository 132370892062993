import React from 'react';
import FadeIn from './FadeIn';
import profilePic from '../assets/akf.png';

const TWITTER_URL = 'https://twitter.com/akoji_f';

const Avatar = () => {
  return (
    <div className="margin30">
      <div className="posRel h145p op0 opAnimator " style={{ animationDelay: '0.07s'}}>
        <a href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
          <img alt="Francis Akoji" className="posAbs avatarImg " width="400" height="400" src={profilePic}/>
        </a>
      </div>
      <div className="marginT20">
        {/* <div className="padT10 width100 textcenter avatarInto oh">
          <FadeIn className="lh2em" style={{ animationDelay: '0.6s' }}>a blog by <span><a href={LINKEDIN_URL} target="_blank" className="themeColor linkHand" rel="noopener noreferrer">dhilip</a></span></FadeIn>
        </div> */}
        <div className="padT10 width100 textcenter avatarInto oh">
          <FadeIn className="lh2em" style={{ animationDelay: '0.15s' }}>Software Engineer @astartup {/*<a href="https://blueroomcare.com/" target="_blank" rel="noopener noreferrer"><span className="linkHand">Blueroomcare</span></a>*/}</FadeIn>
        </div>
        <div className="padT10 width100 textcenter avatarInto oh">
          <FadeIn className="lh2em" style={{ lineHeight: '3em', animationDelay: '0.20s' }}> Nothing to see here <span className="emoji" role="img" aria-label="">🚶🏻</span></FadeIn>
        </div>
      </div>
    </div>
  );
}
export default Avatar;
